import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our annual 12 Days of Christmas workout is today!  Classes will be
at 10:30 & 12:00 and are free to all!  All other class times and open
gym are cancelled.`}</em></strong></p>
    <p>{`12 Days of Christmas`}</p>
    <p>{`1-Thruster (75/55) (RX+ 95/65)`}</p>
    <p>{`2-Push Press`}</p>
    <p>{`3-Push Jerk`}</p>
    <p>{`4-Split Jerk`}</p>
    <p>{`5-Power Cleans`}</p>
    <p>{`6-Pullups (RX+ CTB’s)`}</p>
    <p>{`7-Burpees`}</p>
    <p>{`8-Box Jumps (24/20)`}</p>
    <p>{`9-Push Ups`}</p>
    <p>{`10-Front Rack Lunges (5/leg)`}</p>
    <p>{`11-KBS (53/35) (RX+ 70/53)`}</p>
    <p>{`12-Double Unders`}</p>
    <p><em parentName="p">{`alternate version:`}</em></p>
    <p>{`1-D Ball Clean`}</p>
    <p>{`2-Jump Squats`}</p>
    <p>{`3-Lunges/Leg`}</p>
    <p>{`4-Back Extensions`}</p>
    <p>{`5-Ring Rows`}</p>
    <p>{`6-Burpees`}</p>
    <p>{`7-Wall Balls (20/14)`}</p>
    <p>{`8-V Ups`}</p>
    <p>{`9-Push Ups`}</p>
    <p>{`10-Sit Ups`}</p>
    <p>{`11-KBS’s (53/35)`}</p>
    <p>{`12-DB Push Press`}</p>
    <p><em parentName="p">{`*`}{`Performed like the song.  1, 2-1, 3-2-1, 4-3-2-1, etc.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be closed Christmas Day and reopen on Thursday for the 10:30
class.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      